<template>
  <div class="input-wrapper">
    <label v-if="label" :for="inputId">{{ label }}</label>
    <input
      :id="inputId"
      :type="type"
      :value="modelValue"
      v-bind="$attrs"
      class="form-control form-control-lg"
      @input="updateValue"
    />
    <span v-if="errorMessage" class="error">{{ errorMessage }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const props = defineProps<{
  modelValue: string;
  label?: string;
  type?: string;
  errorMessage?: string;
}>();

const emit = defineEmits(['update:modelValue']);

const inputId = ref<string>('');

onMounted(() => {
  inputId.value = `input-${Math.random().toString(36).substr(2, 9)}`;
});

const updateValue = (event: Event) => {
  const target = event.target as HTMLInputElement | null;
  if (target) {
    emit('update:modelValue', target.value);
  }
};
</script>

<style scoped>
.input-wrapper {
  position: relative;
}
.error {
  color: red;
  font-size: 0.875em;
}
</style>
